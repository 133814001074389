import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
//import ScrollMagic from 'ScrollMagic';
import { TweenMax, Power2, TimelineLite } from "gsap/TweenMax";

const body = document.getElementById('body');
const main = document.getElementById('main');
const logo = document.getElementById('nav-logo');
const hamburger = document.getElementById('menu-container');
const cursor = document.getElementById('cursor');
const follower = document.getElementById('cursor-follow');
const links = document.getElementsByClassName('link');

let posX = 0;
let posY = 0;

let mouseX = 0;
let mouseY = 0;

TweenMax.to({}, 0.016, {
	repeat: -1,

	onRepeat() {

		posX += (mouseX - posX) / 9;
		posY += (mouseY - posY) / 9;

		TweenMax.set(follower, {
			css: {
				left: posX - 12,
				top: posY - 12
			}
		});

		TweenMax.set(cursor, {
			css: {
				left: mouseX,
				top: mouseY
			}
		});
	}
});

body.addEventListener('mousemove', moveCursor);

for (let i = 0; i < links.length; i++) {
	links[i].addEventListener('mouseenter', enterCursor);
	links[i].addEventListener('pointerenter', enterCursor);
	links[i].addEventListener('mouseleave', leaveCursor);
}

function moveCursor(e) {
	mouseX = e.pageX;
	mouseY = e.pageY;
}

function enterCursor() {
	cursor.classList.add('active');
	follower.classList.add('active');
}

function leaveCursor() {
	cursor.classList.remove('active');
	follower.classList.remove('active');
}

// SCROLLBAR PLUGIN EVENT & INIT

// class EdgeEasingPlugin extends Scrollbar.ScrollbarPlugin {
// 	static pluginName = 'edgeEasing';

// 	private _remainMomentum = {
// 		x: 0,
// 		y: 0,
// 	};

// 	transformDelta(delta) {
// 		const {
// 			limit,
// 			offset,
// 		} = this.scrollbar;

// 		const x = this._remainMomentum.x + delta.x;
// 		const y = this._remainMomentum.y + delta.y;

// 		// clamps momentum within [-offset, limit - offset]
// 		this.scrollbar.setMomentum(
// 			Math.max(-offset.x, Math.min(x, limit.x - offset.x)),
// 			Math.max(-offset.y, Math.min(y, limit.y - offset.y)),
// 		);

// 		return { x: 0, y: 0 };
// 	}

// 	onRender(remainMomentum) {
// 		Object.assign(this._remainMomentum, remainMomentum);
// 	}
// }

// Scrollbar.use(EdgeEasingPlugin);

const scrollbar = Scrollbar.init(main, {
	speed: 1,
	syncCallbacks: true,
	damping: 0.04,
	thumbMinSize: 40,
	renderByPixels: true,
	alwaysShowTracks: false,
	continuousScrolling: false,
	overscrollEffect: null
});

// CHECKING SCROLL DIRECTION

let lastOffset = null;

scrollbar.addListener(({ offset }) => {
	if (!lastOffset) {
		lastOffset = offset;
		return;
	}

	const dir = [];

	if (offset.y < lastOffset.y) {
		dir[0] = 'up';
	} else if (offset.y > lastOffset.y) {
		dir[0] = 'down';
	} else {
		dir[0] = 'still';
	}

	if (offset.x < lastOffset.x) {
		dir[1] = 'left';
	} else if (offset.x > lastOffset.x) {
		dir[1] = 'right';
	} else {
		dir[1] = 'still';
	}

	lastOffset = offset;

	switch (dir[0]) {
		case 'up':
			if (window.innerWidth < 992) {
				logo.classList.add('pin');
				logo.classList.remove('unpin');
				hamburger.classList.add('pin');
				hamburger.classList.remove('unpin');
			}
			break;
		case 'down':
			if (window.innerWidth < 992) {
				logo.classList.remove('pin');
				logo.classList.add('unpin');
				hamburger.classList.remove('pin');
				hamburger.classList.add('unpin');
			}
			break;
		case 'still':
	}

	switch (dir[1]) {
		case 'left':
		case 'right':
		case 'still':
	}
});